.aside {
    max-width: 250px;
    min-width: 250px;
    max-height: 100%;
    display: flex;
    .detatlles {
        width: 400px;
        transform: translate(14em, 75px);
        height: 80vh;
        z-index: 9999;
        >div:nth-child(1) {
            height: 100%;
            overflow-y: scroll;
            overflow-x: hidden;
        
            &::-webkit-scrollbar {
                width: 12px;
                background-color: transparent
            }
            &::-webkit-scrollbar-thumb {
                border-radius: 15px;
                background-color: rgba(255, 255, 255, 0.247);
            }
            >div {
                padding: 1rem;
            }
        }
    }
    .submenu {
        width: 600px;
        height: 80vh;
        z-index: 99;
        overflow-y: scroll;
        overflow-x: hidden;
    
        transform: translate(20em, 0px);
        z-index: 9999;
        &::-webkit-scrollbar {
            width: 12px;
            background-color: transparent
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 15px;
            background-color: rgba(255, 255, 255, 0.247);
        }
        @media (max-width: 1366px) {
            transform: translate(145px, 0px);

        }
    }

    .subsubmenu {
        width: 600px;
        height: 80vh;
        overflow-y: scroll;
        overflow-x: hidden;
    
        transform: translate(415px, 0px);
        z-index: 9999;
        &::-webkit-scrollbar {
            width: 12px;
            background-color: transparent
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 15px;
            background-color: rgba(255, 255, 255, 0.247);
        }
        @media (max-width: 1366px) {
            transform: translate(125px, 0px);

        }
    }
}

.formularios {
    padding: 1rem;
    .campo {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        input,
        select,
        .react-dropdown-select,
        textarea {
            padding: 0.5rem 1rem;
/*             background-color: rgba($color: #fff, $alpha: 0.4); */
            border: 1px solid #797974;
            /* color: #000000; */
            margin-bottom: 8px;
            resize: none;
            &::placeholder {
                color: #fff;
            }
            &:-ms-input-placeholder {
                /* Internet Explorer 10-11 */
                color: #fff;
            }
            &::-ms-input-placeholder {
                /* Microsoft Edge */
                color: #fff;
            }
        }
        input[type="checkbox"]{
            padding: 0.5rem 1rem;
/*             background-color: rgba($color: #fff, $alpha: 0.4); */
            border: 1px solid #797974;
            /* color: #000000; */
            margin-bottom: 8px;
            resize: none;
            margin-right: 0.3em;
            &::placeholder {
                color: #fff;
            }
            &:-ms-input-placeholder {
                /* Internet Explorer 10-11 */
                color: #fff;
            }
            &::-ms-input-placeholder {
                /* Microsoft Edge */
                color: #fff;
            }
        }
        label {
            color: #000;
        }
        .react-dropdown-select{
            color: #000;
            background-color: #fff;
            border-radius: 0.375rem;
        }
        .react-dropdown-select-input{
            padding: 0 !important;
            opacity: 0;
        }
    }
}



::-webkit-scrollbar {
    width: 12px;
    background-color: transparent
}
::-webkit-scrollbar-thumb {
    border-radius: 15px;
    background-color: rgba(255, 255, 255, 0.247);
}

.scroll{
    overflow-x: hidden;
    overflow-y: scroll;
}