body {
  background-color: #f7f7f7;
  overflow: hidden;
}

.home {
  max-height: 90vh;
}

// Fondo Login, Registro, ForgotPassword
.dafi-fondo-auth {
  background-image: url("../img/fondo_auth.jpg");
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
}

.radio-button input[type="radio"] {
  position: absolute;
  left: -1000em;
}

.radio-button {
  height: 50px;
  cursor: pointer;

  label {
    display: block;
    width: 100%;
    cursor: pointer;
  }

  input[type="radio"]:checked + label {
    background: #f3f4f6;
    color: #000;
  }
}

// MODAL CSV
.modal-csv {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  transform: translate(-26%, -11%);
}

.swal2-container {
  z-index: 999999 !important;
}

// DATE RECOURSE
// .rmdp-button{
//     position: absolute;
//     left: 0px;
//     top: 0;
//     opacity: 0;
//     z-index: 0;
//     width: 100%;
//     height: 100%;
//     min-width: unset !important;
// }

input[type="radio"] + label span {
  transition: background 0.2s, transform 0.2s;
}

input[type="radio"] + label span:hover,
input[type="radio"] + label:hover span {
  transform: scale(1.2);
}

input[type="radio"]:checked + label span {
  background-color: #2c292a; //bg-green-dark
  box-shadow: 0px 0px 0px 1px white inset;
}

//select-react-timezone
.css-yk16xz-control {
  border: 1px solid #797974 !important;
}

.dot {
  height: 16px;
  width: 16px;
  background-color: rgb(239, 68, 68);
  border-radius: 50%;
  display: inline-block;
}

.dot-black {
  height: 16px;
  width: 16px;
  background-color: rgb(0, 0, 0);
  border-radius: 50%;
  display: inline-block;
}


/* Reporteador */
.aside .detatlles > div:nth-child(1) > div#reportador {
  padding: 0;
}

#sidebarReporteador {
  background: #e8e8e8;
}

#sidebarReporteador .typeFilterTitulo {
  font-size: 16px;
  font-weight: 900;
}

#sidebarReporteador nav {
  display: grid;
  gap: 0.5rem;
  width: 100%;
  grid-template-columns: repeat(2,1fr);
  text-align: center;
}
#sidebarReporteador label {
  font-size: 14px;
  font-weight: 900;
}
#sidebarReporteador input:first-of-type {
  margin-bottom: 1rem;
}
#sidebarReporteador input {
  font-size: 14px;
  font-weight: 900;
  padding: 0.55rem 1rem 0.55rem 1rem;
  margin-top: 0.5rem;
  color: #195ca9;
  border: 2px solid #b5ceff;
}

#sidebarReporteador .btnTipoRep, #sidebarReporteador .btnDesRep {
  border: 2px solid #b5ceff;
  display: block;
  color: #195ca9;
  font-size: 14px;
  font-weight: 900;
  padding: 0.25rem 0.55rem;
  background-color: #fff;
}

#sidebarReporteador .btnTipoRep:hover, #sidebarReporteador .btnDesRep:hover {
  background: #195ca9;
  color: #fff;
}

#sidebarReporteador .btnTipoRep.active {
  background: #195ca9;
  color: #fff;
}
#sidebarReporteador .sidebarFiltros select:last-of-type {
  margin-bottom: 0;
}
#sidebarReporteador .sidebarFiltros select {
  display: block;
  width: 100%;
  margin-bottom: 1rem;
  padding: 0.55rem 1rem 0.55rem 2.5rem;
  color: #195ca9;
  font-size: 14px;
  font-weight: 900;
  border: 2px solid #b5ceff;
}

#sidebarReporteador .sidebarFiltros .imgFiltro {
  /* -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; */
  background-position: 0.5rem;
  background-size: 25px;
  background-repeat: no-repeat;
}
/* .imgFiltro:before {
  background-image: url("http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png");
  background-position: calc(100% - 10px);
  background-size: 25px;
  background-repeat: no-repeat;
} */
#contentReporteador {
  background: #8eb5d5;
  background-image: url("../img/Reporteador/mapa.png") ;
  background-repeat: no-repeat;
  background-position: 0rem 6rem; 
  background-size: contain;
}

#contentReporteador img {
  width: 300px;
  height: 100%;
}

#contentReporteador h2 {
  font-size: 34px;
}

.chartBloque {
  background: #fff;
  width: 100%;
  height: calc(100% - 9.5rem);
  padding: 0.5rem;
}

.chartBloque canvas{
  width: 100% !important;
  height: 100% !important;
  max-height: 600px;
  margin: auto;
}

.btnDownloadChart {
  border: 2px solid #b5ceff;
  display: flex;
  align-items: center;
  color: #195ca9;
  font-size: 12px;
  font-weight: 900;
  padding: 0.15rem 0.55rem;
  background-color: #fff;
  height: 30px;
}

.imgExam {
  -webkit-filter: brightness(0%) contrast(200%);
  filter: brightness(0%) contrast(200%);
}

.addNewCard {
  display: flex;
  align-items: center;
}
.btnAddNewTopic {
  width: max-content;
  background: #236da9;
  color: #fff;
  padding: 0.15rem 0.75rem;
  font-size: 16px;
  font-weight: 800;
}

.btnForumSuccess {
  margin: 0.3125em;
  padding: 0.625em 1.1em;
  transition: box-shadow 0.1s;
  box-shadow: 0 0 0 3px transparent;
  font-weight: 500;
  color: #fff;
  font-size: 1em;
  border-radius: 0.25em;
  background: #236da9;
}
.btnForumSuccess:focus {
  box-shadow: 0 0 0 3px #236da985;
}

.btnForumCancel {
  margin: 0.3125em;
  padding: 0.625em 1.1em;
  transition: box-shadow 0.1s;
  box-shadow: 0 0 0 3px transparent;
  font-weight: 500;
  color: #fff;
  font-size: 1em;
  border-radius: 0.25em;
  background: #6e7881;
}
.btnForumCancel:focus {
  box-shadow: 0 0 0 3px #236da985;
}
.cardDiscussion {
  box-shadow: 0px 0px 10px 2px #00000059;
  border-radius: 14px;
  padding: 1rem;
}

.autorCard {
  display: flex;
  align-items: center;
}
.autorCard .autorContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.verticalLine {
  border-left: 1px solid #858585;
  height: 27.5px;
}

.data-table-extensions-filter {
  background-color: #fff;
  display: flex;
  align-items: center;
  border: 1px solid lightgray;
  border-radius: 50px;
  padding: 3px 5px;
  width: 35%;
  input{
      padding: 0.5rem 1rem;
      border: 1px solid #797974;
      margin-bottom: 8px;
      resize: none;
      margin-right: 0.3em;
      width: 100%;
      &::placeholder {
          color: #fff;
      }
      &:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: #fff;
      }
      &::-ms-input-placeholder {
          /* Microsoft Edge */
          color: #fff;
      }
  }
}



